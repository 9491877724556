import { Component, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@nx/core/lib/components/base.control';

@Component({
	selector: 'ui-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: InputComponent,
		},
	],
})
export class InputComponent extends BaseControlComponent {
	@Input() isLowerCase = false;
	@Input() type = 'text';
	@Input() min?: string;
	@Input() max?: string;

	constructor(@Inject(Injector) override injector: Injector) {
		super(injector);
	}
}
